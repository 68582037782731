.footercontainer {
  margin-top: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .footeratas {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .title {
      text-align: center;
      color: #808080;
      font-family: "Playfair Display", Sans-serif;
      font-weight: 700;
      letter-spacing: 3.8px;
      font-size: 40px;
      @media (max-width: 768px) {
        font-size: 29px;
      }
    }
    .subtitle {
      text-align: center;
      color: #7a6341;
      font-family: "Verdana", Sans-serif;
      font-size: 53px;
      font-weight: 800;
      @media (max-width: 768px) {
        font-size: 35px;
      }
    }
    .alamat {
      color: #656565;
      font-family: "Verdana", Sans-serif;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
    }
    .contact {
      margin-top: 10px;
      text-align: center;
      color: #4a4a4a;
      font-family: "Verdana", Sans-serif;
      font-size: 18px;
      font-weight: 300;
    }
  }
  .footerbawah {
    margin-top: 50px;
    height: 60px;
    width: 100%;
    background-color: #7a6341;
    color: #ffffff;
    font-family: "Roboto", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
