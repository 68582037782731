.promocontainer {
  margin-top: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .promocenter {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;

    border-radius: 12px 12px 12px 12px;
    box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.19);
    @media (max-width: 768px) {
      flex-direction: column;
      width: 90%;
    }
    .promoimg {
      width: 55%;
      padding: 25px;
      @media (max-width: 768px) {
        width: 90%;
      }
      .promo {
        width: 100%;
      }
    }
    .promopoint {
      padding: 25px;

      .pointtitle {
        font-family: "Roboto Condensed", Sans-serif;
        font-size: 41px;
        font-weight: 600;
        color: #7a6341;
      }
      .point {
        ul {
          font-family: "Roboto Condensed", Sans-serif;
          font-size: 25px;
          font-weight: 600;
          color: #7a6341;
          padding: 0;
          list-style: none;
          li {
            padding: 5px;
          }
          .disclaimer {
            color: black;
            font-size: 10px;
          }
        }
      }
    }
  }
}
