.productcontainer {
  margin-top: 50px;
  width: 100%;
  .title {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    font-family: "Roboto Condensed", Sans-serif;
    font-size: 57px;
    font-weight: 600;
    color: #7a6341;
  }
  .cardproduct {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    gap: 20px;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
  .cardruko {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
