.aboutcontainer {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .aboutcenter {
    width: 60%;
    @media (max-width: 768px) {
      width: 90%;
    }
    .aboutlogo {
      text-align: center;
      .logo {
        width: 26%;
        @media (max-width: 768px) {
          width: 70%;
        }
      }
    }
    .aboutdes {
      margin-top: 30px;
      text-align: left;
      color: #7a7a7a;
      font-family: "Roboto", Sans-serif;
      font-size: 18px;
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    .aboutbutton {
      margin-top: 50px;
      text-align: center;
      .brochure {
        cursor: pointer;
        font-size: 22px;
        border: none;
        display: inline-block;
        line-height: 1;
        background-color: #7a6341;
        font-size: 20px;
        padding: 12px 24px;
        border-radius: 3px;
        color: #fff;
        fill: #fff;
        text-align: center;
        transition: all 0.3s;
      }
    }
    .abouticon {
      margin-top: 50px;
      gap: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .divicon {
        text-align: center;
        .icon {
          width: 70%;
        }
      }
    }
  }
}
