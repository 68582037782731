.lokasicontainer {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .lokasititle {
      margin-bottom: 50px;
      font-family: "Roboto Condensed", Sans-serif;
      font-size: 57px;
      font-weight: 600;
      color: #7a6341;
      text-align: center;
    }
    .lokasiimg {
      text-align: center;
      .img {
        @media (max-width: 768px) {
          width: 95%;
        }
      }
    }
    .lokasipoint {
      width: 70%;
      text-align: left;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
