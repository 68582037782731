.navbarcontainer {
  background-color: #7a6341;
  width: 100%;

  .navbartext {
    padding: 14px 0px 10px 0px;
    text-align: center;
    color: #ffffff;
    font-family: "Roboto Condensed", Sans-serif;
    font-size: 22px;
    font-weight: 400;
  }
}
